import PropTypes from 'prop-types';
import React from 'react';

export const BUTTON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

function Button({ value, onChangeHandler, className, size }) {
  switch (size) {
    case BUTTON_SIZE.SMALL:
      size = 'font-normal';
      break;
    case BUTTON_SIZE.MEDIUM:
      size = 'font-medium';
      break;
    case BUTTON_SIZE.LARGE:
      size = 'font-bold';
      break;
    default:
      size = 'font-medium';
  }
  return (
    <button
      type='button'
      onClick={(e) => onChangeHandler(e)}
      className={`${
        className || ''
      } ${size} text-white transition-all py-3 px-4 w-fit rounded-2xl cursor-pointer bg-gradient-to-tr from-amber-500 to-amber-300 focus:bg-amber-500 transform hover:-translate-y-1 hover:shadow-lg`}
    >
      {value}
    </button>
  );
}

Button.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Button;
