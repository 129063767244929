import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import './navbar.css';
import { menuData } from '../../data/service-data/ServicesData';
import useContainerDimensions from '../../lib/useContainerDimensions';
import Button from '../button/Button';
import Logo from '../images/Logo';

function PopUpMenu() {
  return (
    <ul className='navbar-menu-drop-down'>
      <li>Menu-item-1</li>
      <li>Menu-item-2</li>
      <li>Menu-item-3</li>
    </ul>
  );
}

function MobileNavBar({ menuOpen, setMenuOpen }) {
  return (
    <div className='ml-auto order-2'>
      <Button
        value='Menu'
        className='navbar-menu-button mx-4 p-3'
        onChangeHandler={() => setMenuOpen(!menuOpen)}
      />
      {menuOpen && PopUpMenu()}
    </div>
  );
}

MobileNavBar.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

// function navigateTo(event) {
//   return (event) => {
//     window.location.href = event.target.value;
//   };
// }

function DesktopNavBar() {
  return (
    <div className='mx-2 ml-auto w-100 order-3 flex flex-row-reverse p-1'>
      <a
        className='mx-4 ml-auto transition-all font-medium py-3 px-4 text-black w-fit rounded-2xl cursor-pointer bg-gradient-to-tr from-amber-100 to-amber-50 focus:bg-amber-500 transform hover:-translate-y-1 hover:shadow-lg hover:bg-black'
        href='#contact-us'
      >
        CONTACT US
      </a>
      {menuData.map((item) => (
        <a className='navbar-menu-button' key={`${item.name}`} href={item.url}>
          {item.name}
        </a>
      ))}
    </div>
  );
}

function NavBar() {
  const componentRef = useRef(null);
  const { width } = useContainerDimensions(componentRef);
  const [navBarIsMenu, setNavBarIsMenu] = useState(width < 600);
  const [menuOpen, setMenuOpen] = useState(false);

  useContainerDimensions(componentRef);

  useEffect(() => {
    if (width < 600) {
      setNavBarIsMenu(true);
    } else {
      setNavBarIsMenu(false);
    }
  }, [width]);

  return (
    <div className='border-b-2 navbar-container' ref={componentRef}>
      <div className='flex flex-row navbar-button-container'>
        <Logo className='navBar-logo' />
        {navBarIsMenu ? (
          <MobileNavBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        ) : (
          <DesktopNavBar />
        )}
      </div>
    </div>
  );
}

export default NavBar;
