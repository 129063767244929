import React from 'react';
import './landing-page.css';

function LandingPage() {
  return (
    <div className='landing-page-container mb-8'>
      {/*<video*/}
      {/*  id='background-video'*/}
      {/*  autoPlay*/}
      {/*  loop*/}
      {/*  muted*/}
      {/*  poster='/resources/images/AdobeStock/AdobeStock_632932138.jpeg'*/}
      {/*>*/}
      {/*  <source*/}
      {/*    src='video.mp4'*/}
      {/*    type='video/mp4'*/}
      {/*  />*/}
      {/*</video>*/}
      <img
        className='landing-page border-2 rounded-2xl border-black p-1 shadow shadow-amber-950'
        alt='landing-page-main-image'
        src='/resources/images/AdobeStock/AdobeStock_618215081.jpeg'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.className = 'landing-page-error';
        }}
      />
      <div className='landing-page-text backdrop-blur p-3 rounded-2xl'>
        Tailored for Success -
        <div>The Freedom to Focus on Improving Your Business.</div>
      </div>
    </div>
  );
}

export default LandingPage;
