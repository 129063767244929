import React, { useContext } from 'react';
import GoogleButton from '../button/google/google.button';
import { Marginer } from '../marginer';
import { AccountContext } from './accountContext';
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from './common';

export function LoginForm(props) {
  const { switchToSignup } = useContext(AccountContext);

  return (
    <BoxContainer>
      <FormContainer>
        <Input type='email' placeholder='Email' />
        <Input type='password' placeholder='Password' />
      </FormContainer>
      <Marginer direction='vertical' margin={10} />
      <MutedLink href='#'>Forget your password?</MutedLink>
      <Marginer direction='vertical' margin='1.6em' />
      <SubmitButton type='submit'>Signin</SubmitButton>
      <Marginer direction='vertical' margin='5px' />
      <GoogleButton text='Sign in with Google' />
      <Marginer direction='vertical' margin='5px' />
      <LineText>
        {`Don't have an account?`}
        <BoldLink onClick={switchToSignup} href='#'>
          Signup
        </BoldLink>
      </LineText>
    </BoxContainer>
  );
}
