import PropTypes from 'prop-types';
import React from 'react';
import './logo.css';
import { Link } from 'react-router-dom';

function Logo({ className }) {
  return (
    <Link className='flex flex-row logo-container' to='/'>
      <img
        src='/resources/images/upaavLogo.png'
        className={`company-logo ${className || ''}`}
        alt='company-logo'
      />
      <div className='flex flex-col pl-1 justify-between w-full'>
        <div />
        <div className='version text-nowrap'>0.0.0 Alpha(Testing)</div>
      </div>
    </Link>
  );
}

Logo.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Logo;
