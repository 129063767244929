import {
  createUserDocumentFromAuth,
  createUserWithEmailAndPasswordCustom,
  signInWithGooglePopup,
  signInWithGoogleRedirect,
} from './firebase.utils';

export const SIGN_TYPE = {
  GOOGLE_POPUP: 'google_pop_up',
  GOOGLE_REDIRECT: 'google_redirect',
  EMAIL_PASSWORD: 'email_password',
};

export default async function customSignIn(type, data) {
  let response = null;
  try {
    switch (type) {
      case SIGN_TYPE.GOOGLE_POPUP:
        response = await signInWithGooglePopup();
        break;
      case SIGN_TYPE.GOOGLE_REDIRECT:
        response = await signInWithGoogleRedirect();
        break;
      case SIGN_TYPE.EMAIL_PASSWORD:
        if (!data || !data.email || !data.password) {
          throw new Error('Invalid email or password');
        }
        response = await createUserWithEmailAndPasswordCustom(data);
        break;
      default:
        break;
    }
    if (response === null) {
      throw new Error('Sign-in failed');
    }
    console.log(response.user, response.accessToken);
    await createUserDocumentFromAuth(response.user, data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}
