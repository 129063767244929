import './service-data.css';
import React, { Fragment } from 'react';

export const menuData = [
  {
    name: 'Sign Up',
    id: 'sign-up',
    url: '/signup',
  },
  {
    name: 'Company',
    id: 'company',
    url: '/company',
  },
  {
    name: 'Services',
    id: 'services',
    url: '/services',
  },
  {
    name: 'Home',
    id: 'home',
    url: '/',
  },
];

export const attributions = [
  {
    value: (
      <Fragment>
        Image by{' '}
        <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25001216.htm#query=web%20developer&position=30&from_view=keyword&track=ais&uuid=a712f13a-36d3-4392-bd8a-107dee3bf5a6'>
          Freepik
        </a>
      </Fragment>
    ),
  },
  {
    value: (
      <Fragment>
        <a
          href='https://www.flaticon.com/free-icons/enterprise'
          title='enterprise icons'
        >
          Enterprise icons created by Dewi Sari - Flaticon
        </a>
      </Fragment>
    ),
  },
  {
    value: (
      <Fragment>
        <a
          target='_blank'
          href='https://icons8.com/icon/1F1VkYe4VqKh/app-development'
          rel='noreferrer'
        >
          App Development
        </a>
        icon by
        <a target='_blank' href='https://icons8.com' rel='noreferrer'>
          {` Icons8`}
        </a>
      </Fragment>
    ),
  },
  {
    value: (
      <Fragment>
        <a href='http://www.freepik.com'>Designed by fullvector / Freepik</a>
      </Fragment>
    ),
  },
];

export const ContactData = [
  {
    name: 'support',
    value: 'support@upaav.com',
  },
  {
    name: 'info',
    value: 'info@upaav.com',
  },
  {
    name: 'sales',
    value: 'sales@upaav.com',
  },
  // {
  //   name: "contactNo",
  //   value: "1234567890",
  // },
  // {
  //   name: "address",
  //   value: "Tagra Kali Ram, Kalka-133302, India"
  // }
];

export const ServicesData = [
  {
    name: 'Web Development',
    id: 'web-development',
    icon: 'faLaptop',
    bg: '/resources/images/freepik/EnterpriseSoftwareDevelopment.jpg',
    src: '/resources/images/web-development.png',
    value: (
      <div>
        <p className='services-para-style'>
          We build functional and engaging web products that meets yours
          objectives, brand identity and target audience. We develop websites
          for businesses of all sizes and industries. Our broad scope of
          technologies allows us to select the best-fit approach for your
          specific project. Our engineers create progressive web apps, a dynamic
          content management system, portfolios websites, e-commerce stores, and
          more. We provide:
          <ul className='services-ul-style'>
            <li className='list-sub-services'>Website Design</li>
            <li className='list-sub-services'>Frontend-End Development</li>
            <li className='list-sub-services'>Backend-End Development</li>
            <li className='list-sub-services'>Maintenance</li>
            <li className='list-sub-services'>Support Services</li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    name: 'Mobile Development',
    icon: 'faMobileScreen',
    id: 'mobile-development',
    src: '/resources/images/Mobile-Development-96.png',
    bg: '/resources/images/freepik/MobileDevelopment-3.jpg',
    value: (
      <div>
        <p className='services-para-style'>
          We provides custom mobile development application services, that help
          you increase your sales and reach to customers. We help you build your
          loyalty with a stable, interactive, and high-performance mobile app.
          <ul className='services-ul-style'>
            <li className='list-sub-services'>
              Mobile App Development Services
            </li>
            <li className='list-sub-services'>App Development Services</li>
            <li className='list-sub-services'>
              Android App Development Services
            </li>
            <li className='list-sub-services'>
              Hybrid (Cross-Platform) App Development Services
            </li>
          </ul>
          <span className='fa-solid fa-user' />
        </p>
        <div className='flex items-center justify-center'>
          <div className='flex flex-row'>
            <img
              src='/resources/images/Android_Robot.png'
              width='100px'
              alt='android'
            />
            <img
              src='/resources/images/apple_company_ios_logo_icon.png'
              alt='ios'
              width='100px'
            />
          </div>
        </div>
      </div>
    ),
  },
  {
    name: 'Enterprise Application Development',
    id: 'enterprise-application-development',
    icon: 'faCloud',
    bg: '/resources/images/freepik/fullvector/1032.jpg',
    src: '/resources/images/app-development.png',
    value: (
      <div>
        <p className='services-para-style'>
          For simple to the heavily complex requirements systems, API
          integration, enterprise software development, data migration, digital
          transformation services, and more, we provide scalable, customizable
          solutions for all your needs. Optimize your data management and
          operational efficiency with our intelligent solutions. Cloud native
          and/or cloud-agnostic we offer:
          <ul className='services-ul-style'>
            <li className='list-sub-services'>
              Enterprise Application Development
            </li>
            <li className='list-sub-services'>API Integration</li>
            <li className='list-sub-services'>
              Enterprise Software Development
            </li>
            <li className='list-sub-services'>Data Migration</li>
            <li className='list-sub-services'>Digital Transformation</li>
          </ul>
        </p>
      </div>
    ),
  },
  {
    name: 'Atlassian Development',
    id: 'atlassian-development',
    icon: 'user-secret',
    src: '/resources/images/vertical-logo-gradient-blue-atlassian.png',
    bg: '/resources/images/Atlassian.png',
    value: (
      <div>
        <p className='services-para-style'>
          Atlassian-Based Apps & Addons(Plugins) Development and Customizations:
          We create software exclusively for your business and ensure that it
          will bring ROI for years to come. No matter if it’s the original
          Atlassian software, custom app or inherited addon, brand new or no
          more supported software – we recode and refine it with modern techs,
          bring to cloud, add new features, and tailor to the changing
          requirements of your business. Time management, reporting, document
          management, and Atlassian administration – all that fall under routine
          tasks can be automated. And we provide custom plugins or script for
          that, while you focus on things that generate value. Just give as an
          idea and we will meet every requirement throughout its realization.
        </p>
      </div>
    ),
  },
];

export const footerData = [
  {
    name: 'Contact',
    subItems: ContactData,
  },
  {
    name: 'Services',
    subItems: ServicesData,
  },
  {
    name: 'Menu',
    subItems: menuData,
  },
];
