import React from 'react';
import Services from '../../components/services/Services';
import LandingPage from './LandingPage';

function HomePage() {
  return (
    <div className='flex flex-col'>
      <LandingPage key='landingPage' />
      {Services()}
      {/* <div className={"p-4"}> */}
      {/* <button className="border border-solid hover:scale-110 duration-200 shadow-md rounded-md bg-orange-400 p-2 text-center will-change-auto mb-2 h-auto" onClick={onButtonClick}>{loggedIn ? "Log out" : "Log in"}</button> */}
      {/*    {(loggedIn ? <div> */}
      {/*        Your email address is {email} */}
      {/*    </div> : <div/>)} */}
      {/* </div> */}
    </div>
  );
}

export default HomePage;
