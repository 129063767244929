import React, { useRef } from 'react';
import { ServicesData } from '../../data/service-data/ServicesData';
import './services.css';
import useContainerDimensions from '../../lib/useContainerDimensions';

function Services() {
  const reference = useRef(null);
  const { width } = useContainerDimensions(reference);
  return (
    <div className='border-2  rounded-md m-2 p-3' ref={reference}>
      <h2 className='border-b-2 rounded-2xl bg-black text-white content-center p-4'>
        <div className=''> Services</div>
      </h2>
      {ServicesData.map((service) => {
        return (
          <div
            key={service.name}
            id={service.id}
            className='anchor rounded border-1 p-1 m-8 flex flex-row'
          >
            <img
              className='w-1/2 rounded-2xl my-3 mr-3'
              width='400px'
              height='400px'
              src={service.bg}
              alt='sa'
            ></img>
            <div className='flex flex-col shadow-purple-900 shadow-lg rounded-2xl border-2 p-4'>
              <h2
                className={`hover:underline text-blue-900 font-extrabold flex items-center justify-center`}
              >
                <div className='relative flex flex-row mb-3 border-b-2 p-4'>
                  <img
                    src={service.src}
                    alt={service.name}
                    className='mr-2'
                    width='40px'
                    height='40px'
                  />
                  <div className='flex items-center'>{service.name}</div>
                </div>
              </h2>
              <div>{service.value}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Services;
