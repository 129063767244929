import React, { useRef } from 'react';
import { attributions, footerData } from '../../data/service-data/ServicesData';
import useContainerDimensions from '../../lib/useContainerDimensions';
import './footer.css';

function displayContact(item, mobile) {
  return (
    <div id='contact-us' key='contact-us'>
      {item.subItems.map((subItem) => (
        <div
          key={subItem.name}
          className={`text-blue-600 text-sm m-0 p-0  ${
            mobile ? 'text-center' : 'text-left'
          }`}
        >
          {subItem.value}
        </div>
      ))}
    </div>
  );
}

function displaySubItems(subItems, isMenu = false, mobile) {
  return subItems.map((subItem) => (
    <div
      key={subItem.name}
      className={`relative ml-0 text-sm focus:bg-amber-500 hover:bg-amber-200 hover:underline mr-8
      ${mobile ? 'w-auto' : ''}`}
    >
      <a className='w-full' href={isMenu ? subItem.url : `#${subItem.id}`}>
        {subItem.name}
      </a>
    </div>
  ));
}

function displayItem(item, mobile) {
  let render;

  switch (item.name) {
    case 'Contact':
      render = displayContact(item, mobile);
      break;
    case 'Menu':
      render = displaySubItems(item.subItems, true, mobile);
      break;
    default:
      render = displaySubItems(item.subItems, false, mobile);
  }

  return <div key={item.name}>{render}</div>;
}

function Footer() {
  const ref = useRef(null);
  const { width } = useContainerDimensions(ref);

  return (
    <div ref={ref} className='page-footer-common'>
      <div
        className={`flex justify-between mb-8 h-fit ${
          width < 600 ? 'flex-col space-y-2 m-auto' : 'flex-row space-x-2'
        }`}
      >
        {footerData.map((item) => {
          return (
            <div
              key={item.name}
              className={`relative m-4 p-3 ${
                width < 600 ? 'text-center' : 'text-left'
              }`}
            >
              <h4 className='text-blue-900 mb-4 font-bold'>{item.name} </h4>
              {displayItem(item, width < 600)}
            </div>
          );
        })}
      </div>
      <div
        className={`footer-copyright-bar ${
          width < 600 ? 'flex-col space-y-2' : 'flex-row space-x-2'
        }`}
      >
        <div className='hover:underline'>©2024 Upaav, All Rights Reserved</div>
        <div className='hover:underline'>
          <a href='/terms-and-conditions'>Terms & Conditions</a>
        </div>
        <div className='hover:underline'>
          <a href='/privacy-policy'>Privacy Policy</a>
        </div>
      </div>
      {attributions.map((item) => {
        return item.value ? (
          <div key={item.name} className='text-xs font-light text-center'>
            {item.value}
          </div>
        ) : null;
      })}
      <div className='text-xs font-light my-4 text-center'>
        Brought to You by Upaav
      </div>
    </div>
  );
}

export default Footer;
