// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDkrHyp6zMjwc1txYpOZ8F8yL85ndFkH-k',
  authDomain: 'upaav-3ecd2.firebaseapp.com',
  databaseURL: 'https://upaav-3ecd2-default-rtdb.firebaseio.com',
  projectId: 'upaav-3ecd2',
  storageBucket: 'upaav-3ecd2.appspot.com',
  messagingSenderId: '554664374810',
  appId: '1:554664374810:web:afaee384c4a2666a2317a3',
  measurementId: 'G-QT7DSPL2ZZ',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

//------------- Start: Auth Methods
export const auth = getAuth();
//------------- Start: Auth Methods: Google
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
});

// //------------- Start: Auth Methods: Google Popup
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
// //------------- End: Auth Methods: Google Popup

// //------------- Start: Auth Methods: Google Redirect
export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);
// //------------- End: Auth Methods: Google Redirect
//------------- END:Auth Methods: Google

//------------- Start: Auth Methods: Email and Password
export const createUserWithEmailAndPasswordCustom = (data) => {
  console.log(data);
  return createUserWithEmailAndPassword(auth, data.email, data.password);
};
//------------- End: Auth Methods: Email and Password

//------------- End: Auth Methods

//------------- Start: DB Methods
export const db = getFirestore();
export const createUserDocumentFromAuth = async (
  userAuth,
  additionalData = {}
) => {
  console.log(userAuth, additionalData);
  const userId = userAuth.uid;
  const userRef = doc(db, 'users', userId);
  const userSnapshot = await getDoc(userRef);
  if (!userSnapshot.exists()) {
    const data = {};
    data.displayName = userAuth.displayName;
    data.email = userAuth.email;
    data.createdAt = userAuth.metadata.createdAt || Date.now();
    data.lastLoginAt = userAuth.metadata.lastLoginAt || Date.now();
    if (additionalData !== {}) {
      data.firstName = additionalData.firstname;
      data.lastName = additionalData.lastname;
      data.terms = additionalData.terms;
      data.displayName =
        additionalData.firstname + ' ' + additionalData.lastname;
    }
    try {
      console.log('User Data: ', data);
      await setDoc(userRef, data);
    } catch (error) {
      console.log('Error creating user', error.message);
    }
  } else {
    console.log('User Doc exits: ' + JSON.stringify(userRef));
  }
  return userRef;
};
//------------- End: DB Methods
