import { motion } from 'framer-motion';
import { React, useState } from 'react';
import styled from 'styled-components';
import BsSignInSignUp from '../bootstrap/login/login.bootstrap.component';
import { AccountContext } from './accountContext';
import { LoginForm } from './loginForm';

const BoxContainer = styled.div`
  width: 400px;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  margin: 1rem;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em 5em;
`;

const BackDrop = styled(motion.div)`
  position: absolute;
  width: 160%;
  height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  top: -340px;
  left: -150px;
  transform: rotate(60deg);
  background: linear-gradient(
    58deg,
    rgba(243, 172, 18, 1) 20%,
    rgba(241, 196, 15, 1) 100%
  );
  z-index: 9;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  font-size: 40px;
  font-weight: 600;
  line-height: 1.24;
  color: #fff;
  text-align: center;
  z-index: 10;
`;

const SmallText = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  margin-top: 7px;
  z-index: 10;
  text-align: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

const backdropVariants = {
  expanded: {
    width: '350%',
    height: '1550px',
    borderRadius: '15%',
    transform: 'rotate(60deg)',
  },
  collapsed: {
    width: '160%',
    height: '550px',
    borderRadius: '50%',
    transform: 'rotate(60deg)',
  },
};

const expandingTransition = {
  type: 'spring',
  duration: 2.3,
  stiffness: 30,
};

const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export default function AccountBox(props) {
  const [isExpanded, setExpanded] = useState(false);
  const [active, setActive] = useState('signin');

  const playExpandingAnimation = () => {
    setExpanded(true);
    setTimeout(
      () => {
        setExpanded(false);
      },
      expandingTransition.duration * 1000 - 1500
    );
  };

  const switchToSignup = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive('signup');
    }, 400);
  };

  const switchToSignin = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive('signin');
    }, 400);
  };

  const contextValue = { switchToSignup, switchToSignin };

  return (
    <LoginFormContainer
      id='LoginFormContainer'
      className='flex justify-center items-center p-2 mt-10'
    >
      <AccountContext.Provider value={contextValue}>
        <BoxContainer className='relative p-2'>
          <TopContainer>
            <BackDrop
              initial={false}
              animate={isExpanded ? 'expanded' : 'collapsed'}
              variants={backdropVariants}
              transition={expandingTransition}
            />
            {active === 'signin' && (
              <HeaderContainer>
                <HeaderText>Welcome</HeaderText>
                <HeaderText>Back</HeaderText>
                <SmallText>Please sign-in to continue!</SmallText>
              </HeaderContainer>
            )}
            {active === 'signup' && (
              <HeaderContainer>
                <HeaderText>Create</HeaderText>
                <HeaderText>Account</HeaderText>
                <SmallText>Please sign-up to continue!</SmallText>
              </HeaderContainer>
            )}
          </TopContainer>
          <InnerContainer>
            {active === 'signin' && <LoginForm />}
            {active === 'signup' && <BsSignInSignUp />}
          </InnerContainer>
        </BoxContainer>
      </AccountContext.Provider>
    </LoginFormContainer>
  );
}
