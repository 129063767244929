import * as formik from 'formik';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactPasswordChecklist from 'react-password-checklist';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import customSignIn, {
  SIGN_TYPE,
} from '../../../utils/auth.google.signup.popup';
import { AccountContext } from '../../login/accountContext';
import { BoldLink, LineText } from '../../login/common';

const passwordRegex = `@#^~\`$\\(\\)\\[\\]\\{\\}/;€£:÷<+=_\\-,>.'"!%*\\?&\\\\|`;
const schema = yup.object().shape({
  firstname: yup
    .string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  lastname: yup
    .string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup
    .string()
    .min(8, 'Password must be a least 8 characters!')
    .max(30, 'Password must be at max 30 characters!')
    .matches(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${passwordRegex}])[A-Za-z\\d${passwordRegex}]{8,30}$`,
      'Password must be at least 8 characters, must have 1 uppercase, 1 lowercase, 1 digit and one special character'
    )
    .required('Required'),
  confirmPassword: yup.string().required('Required'),
  terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});

function BsSignInSignUp(props) {
  const { Formik } = formik;

  const { switchToSignin } = useContext(AccountContext);
  const handleSubmit = async (formData, { resetForm }) => {
    console.log(formData);
    if (formData.password !== formData.confirmPassword) {
      return;
    }

    try {
      const response = await customSignIn(SIGN_TYPE.EMAIL_PASSWORD, formData);
      console.log(response);
      resetForm();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
        terms: false,
      }}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form
          className='position-relative w-100 lg:w-1/2'
          noValidate
          onSubmit={handleSubmit}
        >
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom01'>
              <Form.Label className='text-sm font-bold'>First name</Form.Label>
              <Form.Control
                name='firstname'
                required
                type='text'
                placeholder='First name'
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.firstname && errors.firstname !== undefined}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.firstname}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom02'>
              <Form.Label className='text-sm font-bold'>Last name</Form.Label>
              <Form.Control
                name='lastname'
                required
                type='text'
                placeholder='Last name'
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.lastname && !!errors.lastname}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.lastname}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom04'>
              <Form.Label className='text-sm font-bold'>Email</Form.Label>
              <Form.Control
                name='email'
                type='email'
                placeholder='foo@example.com'
                required
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type='invalid'>
                Please provide a valid Email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom05'>
              <Form.Label className='text-sm font-bold'>Password</Form.Label>
              <Form.Control
                name='password'
                type='password'
                placeholder='password'
                required
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
              />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationCustom05'>
              <Form.Label className='text-sm font-bold'>
                Confirm Password
              </Form.Label>
              <Form.Control
                name='confirmPassword'
                type='confirmPassword'
                placeholder='re-enter password'
                required
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
              />
            </Form.Group>
          </Row>
          {touched.password && (
            <Row className='mb-3 mx-3 w-auto'>
              <ReactPasswordChecklist
                className='text-xs text-blue-900'
                rules={[
                  'minLength',
                  'capital',
                  'lowercase',
                  'specialChar',
                  'number',
                  'match',
                ]}
                minLength={8}
                value={values.password}
                valueAgain={values.confirmPassword}
              />
            </Row>
          )}
          <Form.Group className='mb-4 mt-2' controlId='validationCustom06'>
            <Form.Check
              required
              name={'terms'}
              label='Agree to terms and conditions'
              feedback='You must agree before submitting.'
              feedbackType='invalid'
              isInvalid={touched.terms && !!errors.terms}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Row className='mb-3'>
            <Button type='submit'>Sign up</Button>
          </Row>
          <Row className='mb-3'>
            <LineText className='text-center'>
              Already have an account?
              <BoldLink className='ml-2' onClick={switchToSignin} href='#'>
                LogIn
              </BoldLink>
            </LineText>
          </Row>
          {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
          {/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
          {/*<pre>{JSON.stringify(touched, null, 2)}</pre>*/}
        </Form>
      )}
    </Formik>
  );
}

export default BsSignInSignUp;
