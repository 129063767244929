import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountBox from './components/login';
import Page from './components/page/Page';
import Services from './components/services/Services';
import ContactUs from './page/contact-us/ContactUs';
import HomePage from './page/home/home.page';
import PrivacyPolicy from './page/privacy-policy/PrivacyPolicy';

function AboutUs() {
  return null;
}

function App() {
  return (
    // Add routes for login and homepage
    <Router>
      <Routes>
        <Route path='/' element={<Page />}>
          <Route index element={<HomePage />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='services' element={<Services />} />
          <Route path='About-us' element={<AboutUs />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='company' element={<div> Company </div>} />
          <Route path='signup' element={<AccountBox />} />
          {/*<Route path='signup' element={<BsSignInSignUp />} />*/}
        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
