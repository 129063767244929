import React from 'react';
import { Outlet } from 'react-router';
import Footer from '../footer/Footer';

import NavBar from '../nav-bar/NavBar';
import './page.css';

function Page() {
  return (
    <div className='flex flex-column justify-evenly relative'>
      <NavBar />
      <div className='page-body'>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Page;
